import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/login'
//import car from '../components/Carousel'

//import {getLoggedInUser} from '@Appmart/utility'

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth/v2authsource_05082022',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/car',
  //   name: 'car',
  //   component: car
  // }
]

const router = new VueRouter({
  // mode: "history",
  routes
})

export default router
