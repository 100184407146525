<template>
<!-- <div class="main-wrap" style="height: 100vh;"> -->
<div>
    <div class="row" style="height: 100vh;background:linear-gradient(90deg, rgba(10,77,105,1) 29%, rgba(25,104,136,1) 55%, rgba(10,77,105,1) 90%);">
        
       
        <div class="col-md-6" style="background:; margin:auto; padding:5px;">
         <div style="margin-top:-15%; text-align: center;color:#fff; font-size: 18px; font-weight: 600; margin-bottom: 10px;letter-spacing:2px"> 
          {{config.schoolName.toUpperCase()}} CENTRAL PORTAL
                    </div>
<div>

                <div class="card col-md-7 pa-4 " style="margin:auto; margin-top:-0%; border:0">
        <div class="row" v-if="level1">
                    <div style="font-size:26px; color:#666; margin:0 0 20px 0">Login</div>
                    <!-- <b-form @submit.stop.prevent> -->
                    <v-text-field :disabled='activity' label="UserID" :placeholder="config.schoolslug+' Email / CIRMS ID'" outlined dense v-model="userId"  :hint="'Use your school email address or your CIRMS ID as your UserID.'" style="margin-bottom:10px;" persistent-hint></v-text-field>
                    <v-text-field :disabled='activity' label="Password" placeholder="Password" outlined type="password" dense v-model="password" @click.right.prevent @paste.prevent></v-text-field>
                    <!-- <b-form-input class="py-2" placeholder="User ID" v-model="userId" id="feedback-user"></b-form-input> -->
                    <!-- <b-form-input class="py-2 mt-7 mb-2" type="password" v-model="password" id="text-password" placeholder="Password" aria-describedby="password-help-block"></b-form-input> -->
                    <!-- </b-form> -->
                    <div class="col-md-3"></div>
                    <div class="col-md-9">
                    <a href="https://cirs.unizik.edu.ng/sso-password-reset" style="text-decoration: none; color: gray;">
                        <p class="text-right" style="font-size:12px">
                            <strong>Forgot Password ? Clcik here to reset your password on CIRMS</strong>
                        </p>
                    </a>
                    </div>
                    <button v-if="!activity" class="btn"  @click="submit()" style="background-color:#0C4D6A;color:white;padding:10px;cursor: pointer !important;" :disabled='isDisabled'>
                        <strong>Login
                            
                        </strong>
                    </button>
                    <button v-else class="btn" style="background-color:#0C4D6A;color:white;padding:10px;cursor: pointer !important;" disabled>
                        <strong>Please Wait ...
                            
                        </strong>
                    </button>
</div>

<div class="row" v-if="level2">

<!-- OTP AUTHENTICATOR-->
<div v-if="!authprocessing">
<div class="row">
                    <div style="font-size:26px; color:#666; margin:0 0 20px 0">Authenticate your Login</div>
                    <div>Two factor authentication is setup for this account.<br>You need to provide the daily one time password sent to your email to continue.</div>
 <v-otp-input
  length="6" :disabled="loading"
        @finish="onFinish" name ="OTP" v-model="otp" type="number"
></v-otp-input>
                    <div class="col-md-3"></div>
 <div class="col-md-9">
                    <a href="https://cirs.unizik.edu.ng/sso-password-reset" style="text-decoration: none; color: gray;">
                        <p class="text-right" style="font-size:12px">
                            <strong>Resend Otp</strong>
                        </p>
                    </a>
                    </div>
</div>

</div>
<!-- ACTIVITY ROLLER-->
  <v-row
        class="fill-height"
        align-content="center"
        justify="center" v-else
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
        Confirming Authentication
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            indeterminate
            rounded
            height="6"
            striped
          ></v-progress-linear>
        </v-col>
      </v-row>

</div>
                    
                </div>
             <div class="col-md-7 pa-1 " style="margin:auto; margin-top:-0%; border:0; color:#fff; font-size:10px;letter-spacing:2px">{{config.skolasuiteVersion}}</div>

             <div class="col-md-7 pa-1 text-center" style="margin:auto; margin-top:10px; border:0; color:#fff; font-size:14px;letter-spacing:2px">&copy;2022 {{config.schoolName}}</div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
/***
 *  Skolasuite Pro
 *  Login
 *  V2. 
 *  Wireframe : Daberechi
 *  Contirbutors : KalChuka, Ralph, Kenechukwu , Junior
 * 
 *   EXTRA NEED TO KNOW
 *   password is fied has right click diabled inline also ctrl V also disabled
 *  
 * 
 * 
 */

// import "bootstrap/dist/css/bootstrap.css"
import Swal from 'sweetalert2';
// import axios from 'axios'
import router from '@/router/index'
import {parseJwt,config} from '@Appmart/utility'
import axios from 'axios'

export default {
    data: () => ({
        userId: process.env.VUE_APP_AUTH_V1_API_USER,
        password: '',
        activity:false,
        //// config from Utility
        config:config(),
        /// hold the user agent and userOS. this was used at the mounted hook manage browser and OS
        userAgent:"",
        userOs:"",
        level1:true,
        level2:false,
        otp:'',
        authprocessing:false  /// processing OTP 
    }),
    computed: { 
        
        //Disable the submit button until these conditions are met
        isDisabled: function() {
      return !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.userId) ||  this.password.length < 4

    }
    },
   
mounted(){
////// determine the Operating system and the browser.. use this ehnr building login payload
this.userAgent = navigator.userAgent || '';
    this.userOs = navigator.platform || '';
},

    methods: {

//          getCookie(cname) {
//   let name = cname + "=";
//   let decodedCookie = decodeURIComponent(document.cookie);
//   let ca = decodedCookie.split(';');
//   for(let i = 0; i <ca.length; i++) {
//     let c = ca[i];
//     while (c.charAt(0) == ' ') {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) == 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return false;
// },

///// REMOVE THIS METHOD LATTER NOT USEFULL AGAIN FOR NOW
// ShowActivityLoader() {    
//   if (document.querySelector("#pleaseWaitDialog") == null) {
//       var modalLoading = '<div class="modal" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false" role="dialog" style="margin-top:40vh">\
//           <div class="modal-dialog" style="background-color:transparent;border:none">\
//               <div class="modal-content" style="background-color:transparent; text-align:center;border:none">\
//                   <div class="modal-body">\
//                       <div class="spinner-border" role="status" style="color:#fff">\
//   <span class="sr-only"></span>\
// </div>\
// <div style="color:#fff">Loading ...</div>\
//                   </div>\
//               </div>\
//           </div>\
//       </div>';
//       $(document.body).append(modalLoading);
//   }

//   $("#pleaseWaitDialog").modal("show");
// },
// hideActivityLoader() {
//   $("#pleaseWaitDialog").modal("hide");
// },

// parseJwt (token) {
//     var base64Url = token.split('.')[1];
//     var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//     var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
//         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//     }).join(''));

//     return JSON.parse(jsonPayload);
// },

onFinish(){
    this.authprocessing = true
const data = {
                    email: this.userId,
                    otp: this.otp,
                };
                 var headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');

const url = `${this.$appUrl}/otpFacheck`
                fetch(url, {
                    method: 'POST',
                    redirect: 'follow',
                    credentials: 'include', // Don't forget to specify this if you need cookies
                    headers: headers,
                    body: JSON.stringify(data)
                }).then(async response => {
                        const result = await response.json();
                        // const result = response.data
                        console.log(result) // this.$router.push('/login')
                        if (result.code != '00') {
                            Swal.fire({
                                icon: 'error',
                                title: 'Login Error',
                                text: result.message,
                            })
    this.authprocessing = false
    this.otp ='';
                            return
                        } else {
                            const decoded = parseJwt(result.message)
                            console.log(decoded);
 this.finaliseLogin(result,decoded)
    this.authprocessing = false
    this.otp ='';
                        }
                }).catch(error => {
console.log(error);
                })
},

async finaliseLogin(result,decoded){
    //////// use javascript to set the cookie//// i am doind this so i can use a parcel for it latter to read and write cookies
                            let d = new Date();
                            d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
                            let expires = "expires=" + d.toUTCString();
                            document.cookie = "-X_Zka=" + result.message + ";" + expires + ";path=/";
///// check if there is a default set menu. this shouldnt actually be a problem since when onboarding a staff, the default menu should be set from the back end
// if(!decoded.default_menu){
//                     Swal.fire({
//                                 icon: 'error',
//                                 title: 'Login Error',
//                                 text: 'eNDM error. Contact Portal Administrator', // No Default Menu
//                             })
//                             this.activity=false
//                             this.level2 = false;
//                             this.level1 = true
//                             this.password =''
//                             return
// }
//                             router.push({
//                                 path: decoded.default_menu
//                             })


///// remove this after setting up the menu for the super administrator
//  router.push({
//                                 path: "/department/profile-course"
//                             })

const allsubmenus = []

/// Get the submenus
result.menu.data.forEach( element => {
    element.sub_menu.forEach(x => {
        allsubmenus.push(x)
    });
});



// const defaultMenu = allsubmenus.filter(x=>{
//     return x.default == true;
// })
const defaultMenu = decoded.default_menu.filter(element=>{
    return element.programId == '6176addadfe4749cf0a90957'
})
console.log('allsubbssss2',defaultMenu);

/// if there is no default menu, Navigate to the 
if(defaultMenu.length <= 0 ){
    window.location.href =`${window.location.origin}/#/dashboard/settings`;

}else{
    // use [0] incase if its more than 1 pick the first one
    window.location.href =`${window.location.origin+'#'+defaultMenu[0].subMenuLink}`;

}
console.log(result.menu.data);
console.log(allsubmenus);
// window.location.href =`${window.location.origin}/#/department/host-course`;

},

        submit() {
this.activity=true
            // this.ShowActivityLoader()
            /// not necessary but just leave it.
            if (this.userId == '' || this.password == '') {
                Swal.fire({
                    icon: 'error',
                    title: 'Empty Form',
                    text: 'Fill the Form',

                })
            } else {

                /// build the login payload
                const data = {
                    email: this.userId,
                    password: this.password,
                    app_name: this.config.app_name,
                    channelType: this.config.channelType,
                    browser: this.userAgent,
                    os:this.userOs,
                };
                var headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');

const url = `${this.$appUrl}/adminlogin`
                fetch(url, {
                    method: 'POST',
                    redirect: 'follow',
                    credentials: 'include', // Don't forget to specify this if you need cookies
                    headers: headers,
                    body: JSON.stringify(data)
                }).then(async response => {
                        const result = await response.json();
                        // const result = response.data
                        console.log(result) // this.$router.push('/login')
                        if (result.code != '00') {
                            Swal.fire({
                                icon: 'error',
                                title: 'Login Error',
                                text: result.message,
                            })
                            this.activity=false
                            return
                        } else {
                            /// check if 2FA is enabled 
                            if(result.fa2 == false){ /// 2fa not enabled
                            const decoded = parseJwt(result.message)
                            console.log(decoded);
                   this.finaliseLogin(result,decoded) // finalise login and send to dashboard
     
                        }


                            if(result.fa2 == true){ /// 2fa not enabled
  this.authprocessing = false // set OTP processing to false getting ready for processing in the next step. this is incase it were to be true
        this.level1 = false; this.level2=true
                             }

                        }
                    }

                    //     response => {
                    //     console.log(response.data)
                    //                         const result = response.data
                    //                         console.log(result) // this.$router.push('/login')
                    //                         if (result.code != '00')
                    //                             Swal.fire({
                    //                                 icon: 'error',
                    //                                 title: 'Login Error',
                    //                                 text: result.message,
                    //                             })
                    // else
                    // router.push({
                    //                                 path: 'dashboard'
                    //                             })
                ).catch(error=>{
                    console.log(error);
                    this.activity=false
                     Swal.fire({
                                icon: 'error',
                                title: 'Login Error',
                                text: 'PNID error. Contact portal administrator', // Processing Node is Down
                            })
                })
                //             axios
                //                 .post('http://localhost:5003/v1/admin/adminlogin', data,{
                //     withCredentials: true,
                //     credentials: 'include',
                //   })
                //                 .then(response => {
                //                     const result = response.data
                //                     console.log(result) // this.$router.push('/login')
                //                     if (result.code != '00')
                //                         Swal.fire({
                //                             icon: 'error',
                //                             title: 'Login Error',
                //                             text: result.message,
                //                         })
                //                     else

                //                         router.push({
                //                             path: 'dashboard'
                //                         })
                //                 })

            }
        },
    },
}
</script>

<style scoped src="bootstrap/dist/css/bootstrap.css"></style>

<style scoped>

</style>>

</style>
